<template>
  <vx-card class="form-title" title="City-wise Summary">
    <ag-grid-vue
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 my-4 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="summaryList"
      colResizeDefault="shift"
      :animateRows="true"
    >
    </ag-grid-vue>
  </vx-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import BudgetService from "@/services/budgetService.js";

export default {
  components: { AgGridVue },

  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
  },

  watch: {
    projectId(newVal, oldVal) {
      this.getSummaryData();
    },
  },

  data() {
    return {
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Account",
          field: "category_code",
          width: 150,
        },
        {
          headerName: "Description",
          field: "parent_name",
          width: 250,
        },
      ],
      customCols: [],
      summaryList: [],
      pinnedBottomRowData: [
        {
          category_code: "Subtotal",
          parent_name: "",
        },
      ],
    };
  },

  methods: {
    getSummaryData() {
      BudgetService.getCitywiseSummary(this.projectId)
        .then((response) => {
          if (response.data.Status) {
            this.summaryList = response.data.data;

            let subtotal = {};
            Object.keys(response.data.data[0]).map((item) => {
              if (
                item !== "parent_name" &&
                item !== "total" &&
                item !== "category_code"
              ) {
                this.customCols.push({
                  headerName: item,
                  field: item,
                });

                subtotal[item] = 0;
              }
            });
            this.columnDefs.push(...this.customCols, {
              headerName: "Total",
              field: "total",
            });
            subtotal["total"] = 0;

            response.data.data.map((item) => {
              Object.keys(subtotal).map((key) => {
                subtotal[key] += item[key];
              });
            });

            this.pinnedBottomRowData[0] = {
              ...this.pinnedBottomRowData[0],
              ...subtotal,
            };

            this.summaryList.push(this.pinnedBottomRowData[0]);
          }
        })
        .catch((err) => console.log("Error => ", err.message));
    },
  },

  beforeMount() {
    if (this.projectId) {
      this.getSummaryData();
    }
  },
};
</script>

<style></style>
