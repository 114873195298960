<template>
  <div>
    <div class="vx-col w-full">
      <router-link to="/apps/projects">
        <feather-icon icon="ArrowLeftCircleIcon" svgClasses="h-8 w-8" />
      </router-link>
    </div>

    <!-- BUDGET SEARCH -->
    <div class="row">
      <div class="col-12">
        <vx-card class="form-title" title="Filter Budget List">
          <div class="flex flex-wrap items-center justify-end gap-4">
            <span class="mb-2 mr-0 sm:mr-3 w-full sm:w-auto">
              <vs-dropdown
                vs-trigger-click
                class="cursor-pointer w-full sm:w-auto"
              >
                <vs-button
                  class="px-2 py-3 w-full sm:w-auto"
                  color="primary"
                  type="border"
                >
                  <img
                    class="d-inline-flex mr-2"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                    alt
                  />
                  Import
                  <feather-icon
                    icon="ChevronDownIcon"
                    svgClasses="h-4 w-4"
                    class="ml-1"
                  ></feather-icon>
                </vs-button>
                <vs-dropdown-menu class="w-32">
                  <vs-dropdown-item selected @click="popupActivo = true"
                    >Budget</vs-dropdown-item
                  >
                  <vs-dropdown-item @click="importExcelATL"
                    >ATL</vs-dropdown-item
                  >
                </vs-dropdown-menu>
              </vs-dropdown>
            </span>
            <span class="mb-2 mr-0 sm:mr-3 w-full sm:w-auto">
              <vs-dropdown
                vs-trigger-click
                class="cursor-pointer w-full sm:w-auto"
              >
                <vs-button
                  class="px-2 py-3 w-full sm:w-auto"
                  color="primary"
                  type="border"
                >
                  <img
                    class="d-inline-flex mr-2"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                    alt
                  />
                  Sample
                  <feather-icon
                    icon="ChevronDownIcon"
                    svgClasses="h-4 w-4"
                    class="ml-1"
                  ></feather-icon>
                </vs-button>
                <vs-dropdown-menu class="w-32">
                  <vs-dropdown-item
                    selected
                    @click.native="
                      onClickSampleDownload(
                        excelurl + `/v2/budget/excel/sample`
                      )
                    "
                    >Budget</vs-dropdown-item
                  >
                  <vs-dropdown-item
                    @click.native="
                      onClickSampleDownload(
                        excelurl + `/v2/budget/excel/sample/atl`
                      )
                    "
                    >ATL</vs-dropdown-item
                  >
                </vs-dropdown-menu>
              </vs-dropdown>
            </span>
            <!-- <div class="w-full sm:w-1/2 md:w-1/4 p-2 mb-1 md:mb-2"> -->
            <!-- <vs-button
                @click="popupActivo = true"
                class="px-2 py-3 w-full"
                color="primary"
                type="border"
              >
                <img
                  class="d-inline-flex mr-2"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                  alt
                />Import from Excel
              </vs-button> -->
            <!-- </div> -->
            <!-- <div class="w-full sm:w-1/2 md:w-1/4 p-2 mb-1 md:mb-2">
              <vs-button
                @click="importExcelATL"
                class="px-2 py-3 w-full"
                color="primary"
                type="border"
              >
                <img
                  class="d-inline-flex mr-2"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                  alt
                />Import from Excel ATL
              </vs-button>
            </div> -->
            <!-- <div class="w-full sm:w-1/2 md:w-1/4 p-2 mb-1 md:mb-2"> -->
            <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">
                <vs-button
                  class="px-2 py-3 w-full"
                  color="primary"
                  type="border"
                >
                  <img
                    class="d-inline-flex mr-2"
                    src="@/assets/images/icons/excel.svg"
                    height="20"
                    width="20"
                    alt
                  />
                  Sample
                  <feather-icon
                    icon="ChevronDownIcon"
                    svgClasses="h-4 w-4"
                    class="ml-1"
                  ></feather-icon>
                </vs-button>
                <vs-dropdown-menu class="w-32">
                  <vs-dropdown-item
                    selected
                    @click.native="
                      onClickSampleDownload(
                        excelurl + `/v2/budget/excel/sample`
                      )
                    "
                    >Excel</vs-dropdown-item
                  >
                  <vs-dropdown-item
                    @click.native="
                      onClickSampleDownload(
                        excelurl + `/v2/budget/excel/sample/atl`
                      )
                    "
                    >Excel ATL</vs-dropdown-item
                  >
                </vs-dropdown-menu>
              </vs-dropdown> -->
            <!-- <vs-button
                @click.native="
                  onClickSampleDownload(excelurl + `/v2/budget/excel/sample`)
                "
                type="border"
                class="px-2 py-3 w-full"
              >
                <img
                  class="d-inline-flex mr-3"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                />Sample Excel
              </vs-button> -->
            <!-- </div> -->
            <!-- <div class="w-full sm:w-1/2 md:w-1/4 p-2 mb-1 md:mb-2"> -->
            <!-- <vs-button
                @click.native="
                  onClickSampleDownload(
                    excelurl + `/v2/budget/excel/sample/atl`
                  )
                "
                type="border"
                class="px-2 py-3 w-full"
              >
                <img
                  class="d-inline-flex mr-3"
                  src="@/assets/images/icons/excel.svg"
                  height="20"
                  width="20"
                />Sample Excel ATL
              </vs-button> -->
            <!-- </div> -->
          </div>
          <div class="col-12 col-md-6 mx-auto">
            <div>
              <multiselect
                v-model="projectSelected"
                track-by="project_id"
                label="project_name"
                :options="projects"
                name="Project Name"
                placeholder="Select Project"
                :searchable="true"
                :allow-empty="false"
                open-direction="bottom"
                deselect-label="Can't remove this value"
                @input="getProjectBudget"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.project_name }}
                </template>
              </multiselect>
              <p class="error-msg">
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Project Name')"
                  >{{ errors.first("Project Name") }}</span
                >
              </p>
            </div>

            <div cass="row" style="display: none">
              <div class="col-6 mx-auto">
                <div class="col-4 mb-2">
                  <h6>Category</h6>
                  <multiselect
                    label="category_name"
                    class="w-full"
                    track-by="category_id"
                    v-model="categoryValue"
                    name="Category Name"
                    :options="categoryList"
                    :searchable="true"
                    :allow-empty="false"
                    open-direction="bottom"
                    placeholder="Select Option"
                    deselect-label="Can't remove this value"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">{{
                      option.category_name
                    }}</template>
                  </multiselect>
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Category Name')"
                      >{{ errors.first("Category Name") }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 mx-auto"></div>
            </div>
            <div class="text-center w-full sm:w-auto">
              <vs-button
                color="primary"
                class="text-center w-full sm:w-auto"
                type="filled"
                @click="searchBudget('null')"
                >Search</vs-button
              >
            </div>
          </div>
          <div class="flex items-center justify-end">
            <vs-button
              color="dark"
              type="line"
              icon="info"
              title="Budget Edited versions"
              @click="openBudgetVersionPopup"
            />
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-col w-full mt-5">
      <p
        style="background-color: white; padding: 4px"
        v-if="projectSelected.shoot_days > 0"
      >
        Shoot Days
        <span style="font-weight: bold">{{ projectSelected.shoot_days }}</span>
        Setting Days
        <span style="font-weight: bold">{{
          projectSelected.setting_days
        }}</span>
      </p>

      <div
        class="mt-5"
        :key="butnIndx"
        v-for="(butn, butnIndx) in budgetVArray"
      >
        <b-button
          @click="searchBudget(butn)"
          style="float: right"
          pill
          :variant="
            budgetVer == butn.budget_version ? 'danger' : 'outline-danger'
          "
        >
          Version
          <b-badge class="ml-2" variant="light">{{
            butn.budget_version
          }}</b-badge>
        </b-button>
      </div>

      <vs-tabs v-model="tabIndex">
        <vs-tab
          value="1"
          label="Budget Report"
          icon-pack="feather"
          icon="icon-box"
        >
          <!-- BUDGET REPORT -->
          <div>
            <budget-report></budget-report>
          </div>
        </vs-tab>
        <vs-tab
          value="0"
          label="Budget List"
          icon-pack="feather"
          icon="icon-box"
        >
          <div class="tab-text">
            <div>
              <budget-list :project_id="projectId" />
            </div>
          </div>
        </vs-tab>
        <vs-tab value="0" label="ATL List" icon-pack="feather" icon="icon-box">
          <div class="tab-text">
            <div>
              <ATLBudget ref="ATLLIST" />
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <!--BUDGET IMPORT FROM EXCEL POPUP -->
    <vs-popup
      class="holamundo p-2 assigneePopup"
      title="Import Budget from Excel"
      :active.sync="popupActivo"
    >
      <div class="row" v-if="excelErrorData && excelErrorData.length > 0">
        <p class="bg-danger text-warning text-center font-bold px-2 py-1 mb-2">
          There are errors in uploaded excel,
          <span
            @click.prevent="downloadErrorExcel"
            class="text-info cursor-pointer"
          >
            click here
          </span>
          to download excel containing error information, and upload again after
          correcting errors.
        </p>
      </div>
      <div class="text-center position-relative">
        <form>
          <div class="row">
            <div class="col-8">
              <div>
                <multiselect
                  v-validate="'required'"
                  v-model="projects_value"
                  track-by="project_id"
                  open-direction="bottom"
                  label="project_name"
                  @input="
                    ({ project_id }) => (this.form.project_id = project_id)
                  "
                  :options="projects"
                  name="Project Name"
                  placeholder="Select Project"
                  :searchable="true"
                  :allow-empty="false"
                  deselect-label="Can't remove this value"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.project_name }}
                  </template>
                </multiselect>
                <p class="error-msg">
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('Project Name')"
                    >{{ errors.first("Project Name") }}</span
                  >
                </p>
              </div>
              <div class="row mb-5">
                <div class="col-md-8">
                  <h6>Reason (for updating line-items)</h6>
                  <vs-input
                    type="text"
                    v-model="reason"
                    class="w-full"
                    placeholder="Reason"
                    :disabled="this.form.project_id == null ? true : false"
                  />
                </div>
              </div>
              <!-- <div class="row" v-if="is_import_atl == false">
                <div class="col-6">
                  <h6>No. of Shoot days</h6>
                  <vs-input
                    type="number"
                    name="GST"
                    v-model="shoot_days"
                    class="w-full"
                    placeholder="Number of shoot days"
                    :disabled="this.form.project_id == null ? true : false"
                  />
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Priority')"
                      >{{ errors.first("Priority") }}</span
                    >
                  </p>
                </div>
                <div class="col-6">
                  <h6>No. of Setting days</h6>
                  <vs-input
                    type="number"
                    name="GST"
                    v-model="setting_days"
                    class="w-full"
                    placeholder=""
                    :disabled="this.form.project_id == null ? true : false"
                  />
                  <p class="error-msg text-sm">
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('Priority')"
                      >{{ errors.first("Priority") }}</span
                    >
                  </p>
                </div>
              </div> -->
            </div>

            <div class="col-4">
              <vx-tooltip text="Import Excel" position="left">
                <label for="import-file">
                  <input
                    :disabled="this.form.project_id == null ? true : false"
                    ref="uploadExcelFile"
                    type="file"
                    @change="uploadExcel($event)"
                    name="import-file"
                    id="import-file"
                    class="d-none"
                  />
                  <div
                    class="vs-component vs-button px-4 vs-button-primary vs-button-filled"
                    :style="
                      this.form.project_id == null
                        ? 'opacity: .5; cursor: default;pointer-events: none;'
                        : ''
                    "
                  >
                    <span class="d-inline-flex pr-5 text-white">Import</span>
                    <img
                      class="d-inline-flex"
                      src="@/assets/images/icons/import-excel.svg"
                      height="20"
                      width="20"
                    />
                  </div>
                </label>
              </vx-tooltip>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col mx-auto">
              <vs-button
                type="filled"
                class="mr-3"
                :disabled="submitStatus"
                @click.prevent="
                  () => (is_import_atl ? submitExcel(true) : submitExcel())
                "
              >
                Submit
              </vs-button>
            </div>
          </div>
        </form>
      </div>
    </vs-popup>

    <!-- Budget versions popup -->
    <vs-popup title="Budget Updated Versions" :active.sync="budgetVersionPopup">
      <AgGridVue
        class="ag-theme-material w-100 my-4 ag-grid-table"
        style="max-height: 250px;"
        :columnDefs="columnDefs"
        :rowData="budgetVersionPopupData"
      />
    </vs-popup>
  </div>
</template>

<script>
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import projectService from "@/services/projectService.js";
import budgetList from "@/views/apps/budget/budgetList.vue";
import budgetReport from "@/views/apps/budget/budgetReport.vue";
import ATLBudget from "@/views/apps/budget/ATLBudget.vue";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
// import BudgetService from "@/services/budgetService.js";
import { mapActions } from "vuex";
import * as XLSX from "xlsx";
import Multiselect from "vue-multiselect";

import BudgetService from "@/services/budgetService.js";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  data() {
    return {
      columnDefs: [
        { headerName: "User", field: "user_name" },
        { headerName: "Date", field: "created_date" },
        {
          headerName: "version",
          field: "id",
          cellRenderer: (params) => params.rowIndex + 1,
        },
        { headerName: "Reason", field: "reason" },
      ],
      moment: moment,
      budgetVersionPopup: false,
      budgetVersionPopupData: [],
      reason: "",
      excelErrorData: [],
      categoryValue: 0,
      categoryList: [],
      shoot_days: 0,
      setting_days: 0,
      OrganizationID: window.localStorage.getItem("OrganizationID"),
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL,
      popupActivo: false,
      is_import_atl: false,
      isActive: false,
      analyticsData: "",
      isImp: false,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      popupActive: false,
      companies: [],
      form: {
        project_id: null,
      },
      submitStatus: true,
      projects: [],
      projects_value: [],
      excelUpload: null,
      company_value: [],
      projectSelected: [],
      budgetVArray: [],
      organization_id: "",
      budgetVersion: "",
    };
  },
  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    budgetVer() {
      return this.$store.state.budgetversion;
    },
    tabIndex: {
      get() {
        return this.$store.state.tabIndex;
      },
      set(value) {
        this.$store.dispatch("tabIndex", value);
      },
    },
  },
  watch: {
    popupActivo(newVal, oldVal) {
      if (newVal == false) {
        setTimeout(() => {
          this.is_import_atl = false;
        }, 200);
      }
    },
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.clearProjectID();
  },
  mounted() {
    this.organization_id = localStorage.getItem("OrganizationID");

    this.getProjectsList();
    eventBus.$on("refreshBudgetTable", (e) => {
      // this.$store.dispatch("tabIndex", 0);
      // this.$store.dispatch("tabIndex", 0);
      this.searchBudget("null");
    });
    eventBus.$on("refreshTableBudgetList", (e) => {
      this.searchBudget("null");
    });
    eventBus.$on("SetBudgetReportData", (e) => {
      // this.$store.dispatch("tabIndex", 0);
      this.$store.dispatch("tabIndex", 0);
    });
  },
  methods: {
    ...mapActions(["clearProjectID"]),

    downloadErrorExcel() {
      if (this.excelErrorData) {
        let data = [];
        this.excelErrorData.map((item) => {
          var obj = {};
          if (!this.is_import_atl) {
            obj["Code"] = item.budget_code || "";
            obj["Account"] = item.account || "";
            obj["Category Name"] = item.category || "";
            obj["Sub Category Name"] = item.sub_category || "";
            obj["City"] = item.city || "";
            obj["No. of Units 1"] = item.unit1 || "";
            obj["Units Description 1"] = item.unit1_description || "";
            obj["No. of Units 2"] = item.unit2 || "";
            obj["Units Description 2"] = item.unit2_description || "";
            obj["Rate"] = item.rate || "";
            obj["Total"] = item.budget_amount || "";
            obj["Comment"] = item.comment || "";
          } else {
            obj["Account"] = item.account || "";
            obj["Category Name"] = item.category || "";
            obj["Sub Category Name"] = item.sub_category || "";
            obj["CAST (Character Name)"] = item.cast || "";
            obj["ACTOR/TALENT/CREW NAME (As Approved By AV Creative)"] =
              item.crew_name || "";
            obj["STRUCTURE  (Package/ Episodic/Monthly/Per Day/Per Shift)"] =
              item.structure || "";
            obj["NO OF DAYS for Key Talent"] = item.days_for_talent || "";
            obj[
              "BUFFER NO OF DAYS for Key Talent (Where applicable - For No Additional Fees)"
            ] = item.buffer_days_for_talent || "";
            obj["NO. OF PAX (Enter 1 if not applicable. Do not leave blank)"] =
              item.no_of_pax || "";
            obj["RATE (Amount per Eps/Month/Days/Shifts/Package)"] =
              item.rate || "";
            obj["UNITS (# of Days/Eps/Months/Shifts. Value=1 for Package)"] =
              item.unit || "";
            obj["TOTAL FEES FOR SEASON -PRODUCER OFFER"] =
              item.producer_offer || "";
            obj["AMAZON COUNTER OFFER"] = item.amazon_counter_offer || "";
            obj["SEASON BUMP UP (5% or 10%)"] = item.season_bump_up || "";
            obj["OPTIONS (1+3 OR Unlimited)"] = item.options || "";
            obj["PRODUCER COMMENTS"] = item.producer_comment || "";
            obj["AV COMMENTS"] = item.account || "";
            obj["av_comment"] = item.account || "";
            obj["Account"] = item.account || "";
            obj["Account"] = item.account || "";
          }
          obj["ERRORS"] = item.errorMessage || "";
          data.push(obj);
        });

        var dataFinal = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataFinal, "Worksheet");
        XLSX.writeFile(
          wb,
          `errorBudgetExcel${this.is_import_atl ? "ATL" : ""}.xlsx`
        );

        this.popupActivo = false;
        this.excelErrorData = [];
      } else {
        return this.$vs.notify({
          title: "Error Excel not available",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },

    openBudgetVersionPopup() {
      BudgetService.getBudgetUploadHistory(
        this.projectSelected.project_id || ""
      )
        .then((response) => {
          if (!response.data.Status) {
            return this.$vs.notify({
              title: "OOPS",
              text: response.data.Message || "Try Again",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.budgetVersionPopupData = response.data.data;
          this.budgetVersionPopup = true;
        })
        .catch((error) => console.error(error.message || "Error"));
    },

    importExcelATL() {
      this.popupActivo = true;
      this.is_import_atl = true;
    },
    submitExcel: function(isATL = false) {
      this.$validator.validateAll().then((result) => {
        let that = this;
        that.$vs.loading();
        let formData = new FormData();
        formData.append("file", this.excelUpload);
        formData.append("project_id", this.projects_value.project_id);
        formData.append("reason", this.reason);
        axios
          .post(
            that.excelurl +
              `/v2/budget/import/excel${isATL ? "/atl" : ""}?organization_id=${
                that.organization_id
              }&setting_days=${this.setting_days}&shoot_days=${
                this.shoot_days
              }`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: localStorage.getItem("access_token"),
              },
            }
          )
          .then((response) => {
            const { data } = response;
            const input = this.$refs.uploadExcelFile;
            input.type = "file";
            if (!data.Status) {
              this.excelErrorData = data.data;
              that.$vs.notify({
                title: "Error!",
                text: data.Message || "Error",
                iconPack: "feather",
                time: 35000,
                icon: "check_box",
                color: "warning",
              });
            } else {
              that.$vs.notify({
                title: "Uploaded!",
                text: data.Message || "Uploaded",
                iconPack: "feather",
                icon: "check_box",
                color: "success",
              });
              this.popupActivo = false;
            }

            that.$vs.loading.close();
            eventBus.$emit("refreshTabledepa", data);
          })
          .catch((error) => {
            that.$vs.loading.close();
            this.popupActivo = false;
            this.is_import_atl = false;
            const input = this.$refs.uploadExcelFile;
            input.type = "text";
            input.type = "file";
            that.$vs.notify({
              title: "Error!",
              text: error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          });
      });
    },
    onClickSampleDownload: function(URL) {
      window.open(URL, "_blank");
    },
    uploadExcel: function(event) {
      this.excelUpload = event.target.files[0];
      this.$vs.notify({
        title: "Your file is imported",
        text: "Kindly click on the SUBMIT button to upload data succesfully",
        time: 5000,
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },

    searchBudget: async function(version) {
      if (this.$refs.ATLLIST) {
        this.$refs.ATLLIST.getATLBudget();
      }
      if (version && version != "null") {
        this.budgetVersion = version;
      }
      let isProjectSelect = Object.keys(this.projectSelected).length;
      if (isProjectSelect > 0) {
        await this.$store.dispatch("getBudgetList", {
          project_id: this.projectSelected.project_id,
          budgetVersion: this.budgetVersion.budget_version
            ? this.budgetVersion.budget_version
            : null,
        });
        setTimeout(async () => {
          await eventBus.$emit(
            "refreshTableBudgetReport",
            this.budgetVersion.budget_version
              ? this.budgetVersion.budget_version
              : null
          );
        }, 2);
        this.$store.dispatch(
          "budgetversion",
          this.budgetVersion.budget_version
            ? this.budgetVersion.budget_version
            : null
        );

        this.$store.dispatch("budgetversionLength", this.budgetVArray.length);
      } else {
        this.$vs.notify({
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    showModal() {
      this.popupActive = true;
      // this.getCompanyList();
    },
    //  getAllProjects
    getProjectBudget: function() {
      this.budgetVArray = [];
      //console.log(this.projectSelected.project_id, "projectSelected");
      projectService
        .getProject(this.projectSelected.project_id)
        .then((response) => {
          const { data } = response;
          if (data.Status && data.budget_ver) {
            this.budgetVArray = data.budget_ver;
          } else {
            this.budgetVArray = [];
          }

          if (this.budgetVArray.length > 0) {
            this.$store.dispatch(
              "budgetversion",
              this.budgetVArray[0].budget_version
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjectsList: function() {
      this.projects = [];
      projectService
        .getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            //  &&
            //               !this.permissionPageAccessArry.includes(
            //                 PermissionVariables.ViewOrganizationProjects
            //               )
            if (
              !this.permissionPageAccessArry.includes(
                PermissionVariables.ViewOrganizationProjects
              ) &&
              this.loginUserRole != "Admin"
            ) {
              data.data.map((x) => {
                if (
                  this.permissionPageAccessArry.includes(
                    "15P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes(
                    "16P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes(
                    "17P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes(
                    "18P" + x.project_id
                  ) ||
                  this.permissionPageAccessArry.includes("19P" + x.project_id)
                ) {
                  this.projects.push(x);
                }
              });
            } else {
              this.projects = data.data;
            }
            if (
              this.$store.state.budgetObject.projectId != null &&
              this.$store.state.budgetObject.projectId != undefined &&
              this.$store.state.budgetObject.projectId != ""
            ) {
              this.projectSelected = data.data.filter((x) => {
                return x.project_id == this.$store.state.budgetObject.projectId;
              });
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    budgetList,
    budgetReport,
    ATLBudget,
    Multiselect,
    AgGridVue,
  },
};
</script>
