<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4>ATL List</h4>

          <div>
            <vs-button
              class="px-4 mb-4 w-full sm:w-auto sm:ml-6"
              @click="() => deleteBudget(selectedbudgetIds)"
            >
              Delete Selected
            </vs-button>
            <span>
              <a
                :href="
                  excelurl +
                    `/v2/budget/excel/download/atl?token=${temp_token}&project_id=${
                      this.projectId
                    }&organization_id=${OrganizationID}`
                "
                target="_blank"
              >
                <vs-button
                  v-if="
                    permissionPageAccessArry.includes(
                      PermissionVariables.BudgetExport
                    ) || loginUserRole == 'Admin'
                  "
                  class="px-4 mb-4 w-full sm:w-auto sm:ml-6"
                >
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </span>

            <vs-button
              class="px-4 mb-4 w-full sm:w-auto sm:ml-6"
              @click="() => deleteBudget([], projectId)"
            >
              Delete Budget
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="listData"
          colResizeDefault="shift"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
          @rowClicked="rowClicked"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import BudgetService from "@/services/budgetService.js";
import viewExpenseDetails from "./viewExpenseDetails.vue";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    VueSuggest,
    viewExpenseDetails,
    VueSimpleSuggest,
  },

  watch: {
    projectId(newVal, oldVal) {
      this.getATLBudget();
    },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },

  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.$store.dispatch("SetCurrentPageBudget", {
            page: val,
          });
          return val;
        } else return 1;
      },
    },
  },

  data() {
    return {
      listData: [],
      OrganizationID: window.localStorage.getItem("OrganizationID"),
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL,
      totalPages: 0,
      gridOptions: {},
      maxPageNumbers: 7,

      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      selectedbudgetIds: [],
    };
  },

  methods: {
    getATLBudget() {
      BudgetService.getATLBudget(this.projectId)
        .then((response) => {
          const { data } = response;
          if (data.Status == true) {
            this.listData = data.data.map((item) => ({
              ...item,
              selected: false,
            }));
          } else {
            this.listData = [];
          }
        })
        .catch((err) => console.log("Error=> ", err.message));
    },

    deleteBudget(budget_ids = [], project_id = null) {
      BudgetService.deleteATLBudget({ budget_ids, project_id })
        .then((response) => {
          if (!response.data.Status == true) {
            return this.$vs.notify({
              title: "OOPS!",
              text: response.data.Message || "Delete failed.",
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.getATLBudget();
          return this.$vs.notify({
            title: "Success",
            text: response.data.Message || "Deleted successfully.",
            iconPack: "feather",
            icon: "check_box",
            color: "success",
          });
        })
        .catch((error) => console.log("Error => ", error.message));
    },

    rowClicked(selected) {
      this.listData = this.listData.map((item) => {
        if (item.budget_id === selected.data.budget_id) {
          if (this.selectedbudgetIds.indexOf(selected.data.budget_id) === -1) {
            this.selectedbudgetIds.push(selected.data.budget_id);
          } else {
            this.selectedbudgetIds.splice(
              this.selectedbudgetIds.indexOf(selected.data.budget_id),
              1
            );
          }
        }
        return {
          ...item,
          selected:
            item.budget_id === selected.data.budget_id
              ? !item.selected
              : item.selected,
        };
      });
    },
  },

  beforeMount() {
    this.columnDefs = [
      {
        headerName: "    ",
        field: "select",
        width: 70,
        cellRenderer: (e) => {
          return `<input type="checkbox" ${
            e.data.selected ? "checked" : ""
          } />`;
        },
      },
      {
        headerName: "Account",
        field: "category_code",
        filter: false,
        width: 150,
      },
      {
        headerName: "Category",
        field: "parent_category_name",
        filter: false,
        width: 250,
      },
      {
        headerName: "Subcategory",
        field: "category_name",
        filter: false,
        width: 250,
      },
      {
        headerName: "CAST (Character Name)",
        field: "cast",
        filter: false,
        width: 200,
      },
      {
        headerName: "ACTOR/TALENT/CREW NAME (As Approved By AV Creative)",
        field: "crew_name",
        filter: false,
        width: 250,
      },
      {
        headerName: "STRUCTURE",
        field: "structure",
        filter: false,
        width: 150,
      },
      {
        headerName: "NO OF DAYS for Key Talent",
        field: "days_for_talent",
        filter: false,
        width: 250,
      },
      {
        headerName: "BUFFER NO OF DAYS for Key Talent",
        field: "buffer_days_for_talent",
        filter: false,
        width: 250,
      },
      {
        headerName: "NO. OF PAX",
        field: "no_of_pax",
        filter: false,
        width: 150,
      },
      {
        headerName: "RATE",
        field: "rate",
        filter: false,
        width: 150,
      },
      {
        headerName: "UNITS",
        field: "unit",
        filter: false,
        width: 150,
      },
      {
        headerName: "TOTAL FEES FOR SEASON -PRODUCER OFFER ",
        field: "producer_offer",
        filter: false,
        width: 250,
      },
      {
        headerName: "AMAZON COUNTER OFFER ",
        field: "amazon_counter_offer",
        filter: false,
        width: 200,
      },
      {
        headerName: "SEASON BUMP UP",
        field: "season_bump_up",
        filter: false,
        width: 200,
      },
      {
        headerName: "OPTIONS",
        field: "options",
        filter: false,
        width: 120,
      },
      {
        headerName: "PRODUCER COMMENTS",
        field: "producer_comment",
        filter: false,
        width: 250,
      },
      {
        headerName: "AV COMMENTS",
        field: "av_comment",
        filter: false,
        width: 250,
      },
    ];

    this.getATLBudget();
  },
};
</script>
