<template>
  <div>
    <div id="ag-grid-demo">
      <div class="row">
        <div class="col-12">
          <vx-card class="form-title mb-base" title="Budget Report List">
            <div class="flex flex-wrap justify-end items-center">
              <div
                class="flex flex-wrap items-center justify-between ag-grid-table-actions-right w-full sm:w-auto"
              >
                <vs-button
                  class="px-4 w-full sm:w-auto"
                  @click.native="downloadExcel"
                >
                  <span class="d-inline-flex pr-5 text-white">Export </span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </div>
            </div>
            <ag-grid-vue
              @cell-clicked="onCellClicked"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowClicked="onRowDataChanged"
              :rowData="budgetReport"
              colResizeDefault="shift"
              :animateRows="false"
              :floatingFilter="false"
              :pinnedBottomRowData="pinnedBottomRowData"
            />
          </vx-card>
        </div>

        <div class="col-12">
          <vx-card class="form-title mb-base" title="Budget Report Chart">
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              />
            </div>
          </vx-card>
        </div>
      </div>
      <CitywiseSummary />
    </div>
    <div v-if="showExpModal == true">
      <view-all-expense-details
        :editExpParamsData="editExpParamsData"
        :budgetVer="budgetVer"
      />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import VueApexCharts from "vue-apexcharts";
import store from "@/store/store";
import BudgetService from "@/services/budgetService.js";
import viewAllExpenseDetails from "./viewAllExpenseDetails.vue";
import CitywiseSummary from "./CitywiseSummary";
import "flatpickr/dist/flatpickr.css";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import * as XLSX from "xlsx";

export default {
  components: {
    AgGridVue,
    VueApexCharts,
    apexchart: VueApexCharts,
    viewAllExpenseDetails,
    CitywiseSummary,
  },

  props: ["listType"],

  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },

  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    budgetVer() {
      return this.$store.state.budgetversion;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.this.getBudgetReportList();
          return val;
        } else return 1;
      },
    },
  },

  data() {
    return {
      editExpParamsData: null,
      showExpModal: false,
      OrganizationID: window.localStorage.getItem("OrganizationID"),
      pinnedBottomRowData: null,
      totalPages: 0,
      archive: this.listType,
      budgetReport: [],
      gridOptions: {
        onRowClicked: function(event) {
          store.dispatch("selectedCode", event.data.budget_code);
          eventBus.$emit("SetBudgetReportData", event.data.budget_code);
        },
      },
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
      series: [
        {
          name: "Actual Amount",
          data: [],
        },
        {
          name: "Budget Amount",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "top",
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: "text",
          categories: [],
        },
        legend: {
          position: "top",
          offsetY: 10,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },

  methods: {
    onCellClicked(params) {
      if (
        params.colDef.field == "category_name" ||
        params.colDef.field == "budget_amount" ||
        params.colDef.field == "actual_amount" ||
        params.colDef.field == "balance_amount"
      ) {
        this.showExpModal = false;
        this.editExpParamsData = params;
        setTimeout(() => {
          this.showExpModal = true;
        }, 300);
      }
    },
    downloadExcel: function() {
      let data = [];
      for (var i = 0; i < this.budgetReport.length; i++) {
        var odata = this.budgetReport[i];
        var obj = {};
        obj.Category = odata.category_name;
        obj.Budget = odata.budget_amount;
        obj.Actual = odata.actual_amount;
        obj.Balance = odata.balance_amount;

        data.push(obj);
      }

      var obj = {};
      obj.Category = "TOTAL";
      obj.Budget = this.pinnedBottomRowData[0]["budget_amount"];
      obj.Actual = this.pinnedBottomRowData[0]["actual_amount"];
      obj.Balance = this.pinnedBottomRowData[0]["balance_amount"];

      data.push(obj);

      var dataOne = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataOne, "BUDGET REPORT"); // sheetAName is name of Worksheet
      XLSX.writeFile(wb, `budgetReport.xlsx`);
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    getBudgetReportList: function(budgetVersion) {
      BudgetService.getBudgetReportsList(
        this.projectId || this.$store.state.budgetObject.projectId,
        this.budgetVer ? this.budgetVer : budgetVersion
      )
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.budgetReport = data.data;
            let budgetReport1 = data.data1;

            this.categories = budgetReport1.map((x) => {
              return x.budget_code;
            });

            let actual_amount_data = budgetReport1.map((x) => {
              return x.actual_amount_new;
            });

            let budget_amount_data = budgetReport1.map((x) => {
              return x.budget_amount_new;
            });

            this.series = this.series.map((x) => {
              if (x.name == "Actual Amount") {
                x.data = actual_amount_data;
              } else if (x.name == "Budget Amount") {
                x.data = budget_amount_data;
              }
              return x;
            });

            this.gridApi.sizeColumnsToFit();
            let obj = {
              budget_code: "Total",
            };
            let budget_amount_new = -0;
            let actual_amount_new = -0;
            let balance_amount_new = 0;
            let contractAmountNew = 0;
            let projectAmount_new = 0;
            let credit_amount_new = 0;

            data.data1.map((x) => {
              budget_amount_new =
                budget_amount_new + parseInt(x.budget_amount_new);
              actual_amount_new =
                actual_amount_new + parseInt(x.actual_amount_new);
              balance_amount_new =
                balance_amount_new + parseInt(x.balance_amount_new);
              contractAmountNew =
                contractAmountNew + parseInt(x.contractAmount_new);
              projectAmount_new =
                projectAmount_new + parseInt(x.projectAmount_new);
              credit_amount_new =
                credit_amount_new + parseInt(x.credit_amount_new);
            });

            obj["balance_amount"] = balance_amount_new;
            obj["actual_amount"] = actual_amount_new;
            obj["budget_amount"] = budget_amount_new;
            obj["projectAmount"] = projectAmount_new;
            obj["contractAmount"] = contractAmountNew;
            obj["credit_amount"] = credit_amount_new;
            this.pinnedBottomRowData = [obj];
            this.updateChart();
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    updateChart() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: this.categories,
          },
        },
      };
    },
  },

  beforeMount() {
    this.columnDefs = [
      {
        headerName: "Code",
        field: "budget_code",
        filter: false,
        width: 70,
      },
      {
        headerName: "Category",
        field: "category_name",
        filter: false,
        width: 180,
      },
      {
        headerName: "Budget",
        field: "budget_amount",
        filter: false,
        width: 100,
      },
      {
        headerName: "Actual",
        field: "actual_amount",
        filter: false,
        width: 100,
      },
      {
        headerName: "Balance",
        field: "balance_amount",
        filter: false,
        width: 100,
      },
    ];

    this.gridOptions = {
      getRowStyle(params) {
        let temp_str = params.data.actual_amount;
        if (temp_str.length > 3) {
          temp_str = params.data.actual_amount.replace(/\,/g, "");
        }

        let temp_str1 = params.data.budget_amount;
        if (temp_str1.length > 3) {
          temp_str1 = params.data.budget_amount.replace(/\,/g, "");
        }
        if (parseInt(temp_str1) < parseInt(temp_str)) {
          return { background: "red" };
        } else {
          return null;
        }
      },
    };

    if (this.projectId) {
      this.getBudgetReportList();
    }
    eventBus.$on("refreshTableBudgetReport", (e) => {
      this.getBudgetReportList(e);
    });
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
</script>
