<template>
  <div>
    <span class="text-center table-edit-icon" @click="getPreview()">
      <i class="text-center material-icons">remove_red_eye</i>
    </span>
    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :id="'popmodalView' + params.data.budget_id"
        class="holamundo p-2"
        title="Budget Detail"
        :active.sync="popupActive"
      >
        <div class="row mx-0 mt-5 mb-base">
          <div class="col-12 col-md-3">
            <h6>Budget Code:</h6>
            <h5 class="text-primary">{{ params.data.budget_code }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <h6>Category:</h6>
            <h5 class="text-primary">{{ params.data.parent_category_name }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <h6>Subcategory:</h6>
            <h5 class="text-primary">{{ params.data.category_name }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <h6>Initial Budget:</h6>
            <h5 class="text-primary">
              {{ params.data.budget_amount.toLocaleString("en-IN") }}
            </h5>
          </div>
        </div>

        <div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="budgetLogs"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
            cellClass="cell-wrap-text"
            :autoHeight="true"
            headerClass="cell-wrap-text"
          ></ag-grid-vue>
          <!-- :paginationPageSize="paginationPageSize"
            :pagination="true"
          :suppressPaginationPanel="true"-->
          <!-- <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" /> -->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DepartmentService from "@/services/departmentService";
import BudgetService from "@/services/budgetService.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
// var moment = require("moment");
// moment().format();
export default Vue.extend({
  components: {
    AgGridVue,
  },
  data() {
    return {
      params: null,
      budgetDetail: [],
      budgetList: [],
      totalPages: 0,
      popupActive: false,
      gridApi: null,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      initial_amount: "0",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
        wrapText: true,
        autoHeight: true,
      },
      columnDefs: [],
      // project_id: ""
    };
  },

  beforeMount: function() {
    this.budget_id = this.params.value;
    // this.budgetDetails = this.params.data;
    this.columnDefs = [
      {
        headerName: "Name",
        field: "user_name",
        filter: false,
        width: 300,
        suppressSizeToFit: true,
      },
      //  {
      //   headerName: "Initial Amount",
      //   field: "initial_amount",
      //   filter: false,
      //   width: 200
      // },
      {
        headerName: "Budget Amount",
        field: "amount",
        filter: false,
        width: 200,
        cellRenderer: function(params) {
          return `<span>${params.value.toLocaleString("en-IN")}</span>`;
        },
      },
      {
        headerName: "Date",
        field: "created_date",
        filter: false,
        width: 200,

        cellRenderer: function(params) {
          return `<span>${moment(parseInt(params.value) * 1000).format(
            "LLLL"
          )}</span>`;
          //  fromNow() previous
        },
      },
      {
        headerName: "Reason",
        field: "reason",
        filter: false,
        width: 350,
        cellStyle: { "white-space": "normal", "line-height": "19px" },
        wrapText: true,
        autoHeight: true,
      },

      // cellRendererFramework: viewBudget
    ];
  },
  mounted() {
    // if (this.checkPermission(["department", "GET"])) {
    //   this.getDepartmentList(this.currentPage);
    // }

    this.gridApi = this.gridOptions.api;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.budget_id
    );
    _id.setAttribute("style", "display: none;");
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getDepartmentList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getPreview: function() {
      this.popupActive = true;
      this.gridApi.sizeColumnsToFit();
      this.getBudgetLogList();
      // BudgetService.getSingleBudget(this.budget_id)
      //   .then(response => {
      //     const { data } = response;
      //     if (data.Status) {
      //       this.budgetDetail = data.data;

      //     } else {
      //     }
      //   })
      //   .catch(error => {
      //     console.log("error :", error);
      //   });
    },
    getBudgetLogList: function() {
      console.log(this.params.data);
      this.initial_amount = this.params.data.budget_amount;
      BudgetService.getBudgetLogs(this.budget_id)
        .then((response) => {
          const { data } = response;
          console.log("object");
          if (data.Status) {
            this.budgetLogs = data.data;

            if (data.data.length > 0) {
              let index = data.data.length - 1;
              this.initial_amount = data.data[index]["amount"];
            }
          } else {
            console.log("error :", error);
          }
        })
        .catch((error) => {});
    },
  },
});
</script>

<style>
.cell-wrap-text {
  white-space: normal !important;
}
</style>
