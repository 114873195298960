<template>
  <div>
    <b-form-checkbox
      @click.native="popupActive = true"
      :value="checkedValue"
      unchecked-value="3"
      v-model="form.is_allowed"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      {{ this.params.data }}
      <vs-popup
        :id="'popmodalS' + params.data.budget_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Budget Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to allow/disallow budget ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import BudgetService from "@/services/budgetService.js";
export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        is_allowed: "1",
      },
      copy_is_allowed: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_is_allowed = this.form.is_allowed;
  },

  computed: {
    checkedValue() {
      if (this.params.data.budget_amount < this.params.data.actual_amount) {
        return "2";
      } else {
        return "1";
      }
    },
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.is_allowed
    );

    // _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      let id = this.params.data.budget_id;
      let payload = {
        ...this.form,
        project_id: this.projectId,
        // budget_id: this.form.budget_id,
        // budget_code: this.form.budget_code,
        // category_name: this.form.category_name,
        // sub_category_name: this.form.sub_category_name,
        // budget_amount: this.form.budget_amount,
        // actual_amount: this.form.actual_amount,
        // is_allowed: this.form.is_allowed,
        // city_id: this.form.city_id,
        // category_id: this.form.category_id,
        // parent_id: this.form.parent_id,
      };
      BudgetService.editBudget(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    privStatus: function() {
      this.popupActive = false;
      this.form.is_allowed = this.copy_is_allowed;
    },
  },
});
</script>
