<template>
  <div v-if="checkPermission(['budget', 'PUT'])">
    <span class="text-center table-edit-icon" @click="showModal">
      <i class="text-center material-icons">edit</i>
    </span>
    <div class="centerx">
      <vs-popup
        :id="'popmodal' + params.data.budget_id"
        class="holamundo editModal"
        title="Update Budget Record"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Budget Code</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input
                        disabled
                        v-model="form.budget_code"
                        class="w-100"
                      />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6" v-if="organization_id == 5">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Fixed/Variable</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <multiselect
                        disabled
                        v-validate="'required'"
                        v-model="fixedOrVarValue"
                        :options="fixedOrVar"
                        :searchable="false"
                        :show-labels="false"
                        open-direction="bottom"
                        name="fixedOrVar"
                        placeholder="Select Option"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('fixedOrVar')"
                        >{{ errors.first("fixedOrVar") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Category</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input
                        disabled
                        v-model="form.parent_category_name"
                        class="w-100"
                      />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Subcategory</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input
                        disabled
                        v-model="form.category_name"
                        class="w-100"
                      />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">City</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input
                        disabled
                        v-model="form.city_name"
                        class="w-100"
                      />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">No. Of Units1</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input v-model="form.unit1" />
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Units 1 Description</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input v-model="form.unit1_description" />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">No. Of Units2</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input v-model="form.unit2" />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Units 2 Description</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input v-model="form.unit2_description" />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Rate</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div>
                      <vs-input v-model="form.rate" />
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6" v-if="organization_id == 5">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Nos</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        type="number"
                        name="Nos"
                        v-model.number="form.nos"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Nos')"
                      >
                        {{ errors.first("Nos") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6" v-if="organization_id == 5">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Period</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        type="number"
                        name="Period"
                        v-model.number="form.period"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Period')"
                      >
                        {{ errors.first("Period") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6" v-if="organization_id == 5">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Rate</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        type="number"
                        name="Periods"
                        v-model.number="form.rate"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Rate')"
                      >
                        {{ errors.first("Rate") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Amount</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        type="number"
                        name="Budget Amount"
                        v-model.number="form.budget_amount"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Budget Amount')"
                      >
                        {{ errors.first("Budget Amount") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-5">
                  <div
                    class="col-12 col-md-4 text-left md:text-right align-self-center"
                  >
                    <h5 class="mb-0">Reason</h5>
                  </div>
                  <div class="col-12 col-md-7">
                    <div class>
                      <vs-textarea
                        v-model="changeReason"
                        class="mb-0"
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button type="filled" class @click.prevent="submitForm">
                  Update
                </vs-button>
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BudgetService from "@/services/budgetService.js";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
export default Vue.extend({
  components: {
    VueSuggest,
    VueSimpleSuggest,
  },

  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
  },

  data() {
    return {
      form: {
        budgetCode_value: "",
        selectedCategory: "",
        selectedSubCategory: "",
        budget_amount: "",
        city_name: "",
        unit1: "",
        unit2: "",
        unit1_description: "",
        unit2_description: "",
        rate: "",
      },
      changeReason: "",
      popupActive: false,
      params: null,
      value: null,
      organization_id: "",
      fixedOrVar: ["Fixed", "Variable"],
      fixedOrVarValue: "",
    };
  },

  methods: {
    showModal() {
      this.popupActive = true;
    },
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (this.changeReason.length > 0) {
          this.$vs.loading();
          if (result) {
            let id = this.params.data.budget_id;
            let payload = {
              ...this.form,
              project_id: this.projectId,
              reason: this.changeReason,
            };
            BudgetService.editBudget(payload, id)
              .then((response) => {
                this.$vs.loading.close();
                const { data } = response;
                this.popupActive = false;
                if (data.Status == true) {
                  this.$vs.notify({
                    time: 5000,
                    title: "Updated!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "success",
                  });
                  this.$store.dispatch("getBudgetList", {
                    project_id: this.projectId,
                  });
                  this.clearForm();
                } else {
                  this.$vs.loading.close();
                  this.$vs.notify({
                    time: 5000,
                    title: "Error!",
                    text: data.Message,
                    iconPack: "feather",
                    icon: "check_box",
                    color: "warning",
                  });
                }
              })
              .catch((error) => {
                this.$vs.loading.close();
              });
          }
        } else {
          this.$vs.notify({
            time: 5000,
            title: "Reason Required",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        }
      });
    },

    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },

  beforeMount() {
    this.form = this.params.data;
    this.budget_id = this.params.value;
    this.organization_id = localStorage.getItem("OrganizationID");
    if (this.organization_id == 5) {
      this.fixedOrVarValue = this.params.data.fixvar;
    }
  },

  beforeDestroy() {
    let _id = document.getElementById("popmodal" + this.params.data.budget_id);
    _id.setAttribute("style", "display: none;");
  },
});
</script>

<style>
pre.selected {
  margin: 8px 0;
  height: 295px;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event {
  background-color: white;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event.selected {
  color: red;
}
.v-model-event:hover {
  border: 1px solid #2874d5;
  background-color: #2874d5;
  color: white;
}
.vue-simple-suggest .suggest-item .text {
  display: inline-block;
  line-height: 1;
  vertical-align: text-bottom;
  overflow: hidden;
  max-width: 72%;
  text-overflow: ellipsis;
}
.vue-simple-suggest .suggest-item .text span {
  white-space: nowrap;
}
.vue-simple-suggest .suggest-item button {
  float: right;
  line-height: 1;
  margin-left: 4px;
}
.vue-simple-suggest-enter-active.suggestions,
.vue-simple-suggest-leave-active.suggestions {
  transition: opacity 0.2s;
}
.vue-simple-suggest-enter.suggestions,
.vue-simple-suggest-leave-to.suggestions {
  opacity: 0 !important;
}
</style>
