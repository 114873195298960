<template>
  <form class="p-5">
    <div class="vx-row items-center justify-center">
      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>Budget Code</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input disabled v-model="form.budget_code" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>Category</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input disabled v-model="form.parent_category_name" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>Sub Category</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input disabled v-model="form.category_name" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right"><h5>City</h5></div>
      <div class="col-12 col-sm-8 mb-3">
        <Multiselect
          v-model="form.city"
          :options="cityList"
          track-by="city_id"
          label="city_name"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          :optionHeight="25"
          openDirection="bottom"
        />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>No. Of Units 1</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input v-model="form.unit1" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>Unit 1 Description</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input v-model="form.unit1_description" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>No. Of Units 2</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input v-model="form.unit2" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>Unit 2 Description</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input v-model="form.unit2_description" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right"><h5>Rate</h5></div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input disabled v-model="form.rate" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right">
        <h5>Budget Amount</h5>
      </div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-input v-model="form.budget_amount" class="w-100" />
      </div>

      <div class="col-12 col-sm-4 text-left sm:text-right"><h5>Reason</h5></div>
      <div class="col-12 col-sm-8 mb-3">
        <vs-textarea v-model="form.reason" class="w-100" rows="4" />
      </div>

      <vs-button type="filled" class @click.prevent="submitForm">
        Update
      </vs-button>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect/src/Multiselect";
import BudgetService from "@/services/budgetService.js";

export default {
  components: { Multiselect },
  props: {
    formData: { type: Object, required: true },
    cityList: { type: Array, required: true, default: () => [] },
  },
  computed: {
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
  },
  watch: {
    formData() {
      this.form = {
        ...this.formData,
        city: {
          city_id: this.formData.city_id,
          city_name: this.formData.city_name,
        },
        reason: "",
      };
    },
  },
  data() {
    return {
      form: { city: {}, reason: "" },
    };
  },
  methods: {
    submitForm() {
      if (!this.form.reason) {
        return this.$vs.notify({
          time: 5000,
          title: "Reason Required",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }

      this.$validator.validateAll().then((result) => {
        this.$vs.loading();
        if (result) {
          let id = this.formData.budget_id;
          let payload = {
            ...this.form,
            city_id: this.form.city.city_id || 0,
            project_id: this.projectId,
          };
          BudgetService.editBudget(payload, id)
            .then((response) => {
              this.$vs.loading.close();
              const { data } = response;
              if (data.Status == true) {
                this.$vs.notify({
                  time: 5000,
                  title: "Updated!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.$emit("close-popup");
                this.$store.dispatch("getBudgetList", {
                  project_id: this.projectId,
                });
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  time: 5000,
                  title: "OOPS!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
            });
        }
      });
    },
  },
};
</script>
