<template>
  <div>
    <div v-if="hideIcon == false" @click="getPreview()">
      {{ params.data[params.colDef.field] }}
    </div>

    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :id="'popmodalView' + params.data.budget_id"
        class="holamundo p-2"
        title="Expense Detail"
        :active.sync="popupActive"
      >
        <div class="row mx-0 text-center mt-5 mb-base">
          <div class="col-12 col-sm-6 col-md-2 mb-5 md:mb-0">
            <h6>Budget Code:</h6>
            <h3 class="text-primary">{{ params.data.budget_code }}</h3>
          </div>

          <div class="col-12 col-sm-6 col-md-4 mb-5 md:mb-0">
            <h6>Category:</h6>
            <h3 class="text-primary">{{ params.data.parent_category_name }}</h3>
          </div>

          <div class="col-12 col-sm-6 col-md-4 mb-5 md:mb-0">
            <h6>Subcategory:</h6>
            <h3 class="text-primary">{{ params.data.category_name }}</h3>
          </div>

          <div class="col-12 col-sm-6 col-md-2 mb-5 md:mb-0">
            <h6>Actual Expense Price:</h6>
            <h3 class="text-primary">
              {{ params.data.actual_amount.toLocaleString("en-IN") }}
            </h3>
          </div>
        </div>

        <div>
          <div class="flex items-center justify-end">
            <div class="w-full sm:w-1/2 md:w-1/6">
              <vs-input
                class="w-full"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-full my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowClicked="onRowDataChanged"
            :rowData="budgetLogs"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="false"
          />
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import BudgetService from "@/services/budgetService.js";
import imagePopUp from "../projects/imagePopUp.vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default Vue.extend({
  components: {
    AgGridVue,
  },
  data() {
    return {
      hideIcon: false,
      params: null,
      expenseDetail: [],
      expenseList: [],
      totalPages: 0,
      popupActive: false,
      gridApi: null,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
    };
  },
  props: {
    editExpParamsData: Object,
    budgetVersn: String,
  },
  beforeMount: function() {
    if (this.editExpParamsData) {
      this.params = this.editExpParamsData;
      this.hideIcon = true;
      this.getPreview();
    }
    this.budget_id = this.params.data.budget_id;
    this.expenseDetail = this.params.data;

    this.columnDefs = [
      {
        headerName: "Project",
        field: "prefixCode",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "User",
        field: "user_name",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Expense Name",
        field: "po_name",
        filter: false,
        width: 200,
      },
      {
        headerName: "Expense Type",
        field: "is_var",
        filter: false,
        width: 150,
        cellRenderer: function(params) {
          return params.data.is_var == 1
            ? "VAR"
            : params.data.is_var == 2
            ? "Credit Note"
            : params.data.is_var == 3
            ? "PCR"
            : "Expense";
        },
      },
      {
        headerName: " Amount",
        field: "formattedAmount",
        filter: false,
        width: 200,
        cellRenderer: function(params) {
          return params.data.is_var == 2
            ? " - " + params.data.formattedAmount
            : params.data.formattedAmount;
        },
      },

      {
        headerName: "Description",
        field: "purchase_description",
        filter: false,
        width: 300,
      },
      {
        headerName: "Status",
        field: "purchase_status",
        filter: false,
        sortable: false,
        width: 150,
        cellRenderer: function(params) {
          if (params.value == 0) {
            return `<div class="text-warning font-bold">Pending<div>`;
          } else if (params.value == 1) {
            return `<div class="text-success font-bold">Approved<div>`;
          } else if (params.value == 2) {
            return `<div class="text-danger font-bold">Rejected<div>`;
          }
        },
      },
      {
        headerName: "Multiple Images",
        field: "purchase_image",
        filter: true,
        width: 200,
        sortable: false,
        cellRendererFramework: imagePopUp,
      },
    ];
  },
  mounted() {
    eventBus.$on(
      "showPopupExpense" +
        this.params.data.budget_id +
        this.params.colDef.headerName,
      (e) => {
        if (this.params.data.budget_id == e.budget_id) {
          console.log(e, "dsfsdf");
          this.popupActive = e.show;
        }
      }
    );

    this.gridApi = this.gridOptions.api;
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalView" + this.params.data.budget_id
    );
    _id.setAttribute("style", "display: none;");
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getDepartmentList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getPreview: function() {
      this.popupActive = true;
      this.getBudgetLogList();
    },
    getBudgetLogList: function() {
      BudgetService.getExpenseLog(this.params.data.budget_id, this.budgetVersn)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            console.log(data.data);
            this.budgetLogs = data.data;
          } else {
            console.log("error :", error);
          }
        })
        .catch((error) => {});
    },
  },
});
</script>
