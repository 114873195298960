<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card class="form-title mb-base" title="Budget List">
        <div>
          <div
            class="block justify-start sm:flex sm:flex-wrap sm:justify-end sm:items-center"
          >
            <div
              class="flex flex-wrap items-center ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 mr-0 sm:mr-4 w-full sm:w-auto md:w-1/2 md:mr-1"
                v-model="searchQuery"
                placeholder="Search..."
              />
              <vs-button
                color="primary"
                class="mr-0 sm:mr-4 md:mr-1 hover-btn mb-4 w-full sm:w-auto"
                type="filled"
                @click="updateSearchQuery(searchQuery)"
              >
                Search
              </vs-button>
            </div>
            <vs-button
              v-if="
                this.permissionPageAccessArry.includes(
                  PermissionVariables.BudgetAdd
                ) || loginUserRole == 'Admin'
              "
              color="primary"
              class="mr-4 hover-btn mb-4 w-full sm:w-auto"
              type="filled"
              @click="showAddBudgetModal"
            >
              Add New
            </vs-button>
            <vs-button
              v-if="
                (isDelete == 1 &&
                  is_data &&
                  this.permissionPageAccessArry.includes(
                    PermissionVariables.BudgetDelete
                  )) ||
                  loginUserRole == 'Admin'
              "
              color="primary"
              class="mr-4 hover-btn mb-4 w-full sm:w-auto"
              type="filled"
              @click="deleteBudgetModal"
            >
              Delete Budget
            </vs-button>

            <vx-tooltip text="Export Excel">
              <template v-if="!this.projectId">
                <vs-button
                  class="mb-4 w-full sm:w-auto"
                  @click.prevent="selectProject"
                >
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </template>

              <template v-else>
                <a
                  :href="
                    excelurl +
                      `/v2/budget/excel/download?token=${temp_token}&project_id=${
                        this.projectId
                      }&organization_id=${organization_id}`
                  "
                  target="_blank"
                >
                  <vs-button
                    v-if="
                      this.permissionPageAccessArry.includes(
                        PermissionVariables.BudgetExport
                      ) || loginUserRole == 'Admin'
                    "
                    class="px-4 mb-4 w-full sm:w-auto"
                  >
                    <span class="d-inline-flex pr-5 text-white">Export</span>
                    <img
                      src="@/assets/images/icons/export-excel.svg"
                      class="d-inline-flex"
                      height="20"
                      width="20"
                    />
                  </vs-button>
                </a>
              </template>
            </vx-tooltip>
          </div>
        </div>

        <ag-grid-vue
          @cell-clicked="onCellClicked"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="listData"
        />
        <div class="flex items-center justify-between">
          <vs-pagination
            :total="pagination.last_page || 0"
            :max="maxPageNumbers"
            v-model="currentPage"
          />

          <div class="mb-2">
            Show&nbsp;
            <multiselect
              :options="limits"
              v-model="limit"
              track-by="value"
              label="label"
              selected-label=""
              select-label=""
              deselect-label=""
              class="inline-block w-16"
              size="sm"
            />&nbsp;Entries Per Page
          </div>
        </div>
      </vx-card>
    </div>
    <div class="centerx">
      <vs-popup
        class="holamundo editModal"
        title="Add New Budget Record"
        :active.sync="popupActive"
      >
        <form>
          <FocusLock>
            <div class="vx-row">
              <div class="col-12">
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Budget Code</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        v-validate="'required'"
                        v-model="form.budgetCode_value"
                        :options="budgetCodes"
                        :searchable="false"
                        :show-labels="false"
                        open-direction="bottom"
                        name="Budget Code"
                        placeholder="Select Budget Code"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Budget Code')"
                      >
                        {{ errors.first("Budget Code") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6" v-if="organization_id == 5">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Fixed/Variable</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        v-validate="'required'"
                        v-model="fixedOrVarValue"
                        :options="fixedOrVar"
                        :searchable="false"
                        :show-labels="false"
                        open-direction="bottom"
                        name="fixedOrVar"
                        placeholder="Select Option"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('fixedOrVar')"
                      >
                        {{ errors.first("fixedOrVar") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Category</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        v-validate="'required'"
                        v-model="form.selectedCategory"
                        :options="categoryTemp"
                        track-by="category_id"
                        label="category_name"
                        open-direction="bottom"
                        name="Category"
                        placeholder="Select Budget Code"
                        @select="onSuggestSelect"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Category')"
                      >
                        {{ errors.first("Category") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Subcategory</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        v-validate="'required'"
                        v-model="form.selectedSubCategory"
                        :options="SubcategoryTemp"
                        track-by="category_id"
                        label="category_name"
                        open-direction="bottom"
                        name="SubCategory"
                        placeholder="Select Budget Code"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('SubCategory')"
                      >
                        {{ errors.first("SubCategory") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">City</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <multiselect
                        v-validate="'required'"
                        v-model="form.city"
                        :options="cityList"
                        track-by="city_id"
                        label="city_name"
                        :searchable="false"
                        :show-labels="false"
                        open-direction="bottom"
                        name="City"
                        placeholder="Select Budget Code"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('City Name')"
                      >
                        {{ errors.first("City") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">No. of Unit1</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.unit1"
                        display-attribute="unit1"
                        :filter-by-query="true"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('unit Name')"
                      >
                        {{ errors.first("unit Name") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Unit1 Description</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.unit1_description"
                        display-attribute="unit1_description"
                        :filter-by-query="true"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('unit description')"
                      >
                        {{ errors.first("unit description") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">No. of Unit2</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.unit2"
                        display-attribute="unit2"
                        :filter-by-query="true"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('unit Name')"
                      >
                        {{ errors.first("unit Name") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Unit2 Description</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.unit2_description"
                        display-attribute="unit2_description"
                        :filter-by-query="true"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('unit description')"
                      >
                        {{ errors.first("unit description") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Rate</h5>
                  </div>
                  <div class="col-7">
                    <div>
                      <vue-simple-suggest
                        v-validate="'required'"
                        v-model="form.rate"
                        display-attribute="rate"
                        :filter-by-query="true"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('rate')"
                      >
                        {{ errors.first("rate") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Amount</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <vs-input
                        v-validate="'required'"
                        name="Budget Amount"
                        type="number"
                        v-model.number="form.budgetAmount"
                        class="w-full"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('Budget Amount')"
                      >
                        {{ errors.first("Budget Amount") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div class="col-md-4 text-right align-self-center">
                    <h5 class="mb-0">Status</h5>
                  </div>
                  <div class="col-7">
                    <div class>
                      <b-form-checkbox
                        value="1"
                        unchecked-value="2"
                        v-model="form.budget_active"
                        name="check-button"
                        switch
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  class="mr-3"
                  @click.prevent="submitForm"
                >
                  Submit
                </vs-button>
              </div>
            </div>
          </FocusLock>
        </form>
      </vs-popup>
    </div>

    <!-- DELETE BUDGET POPUP -->
    <div class="demo-alignment">
      <vs-popup
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Budget Status"
        :active.sync="popupActiveDeleteBudget"
      >
        <div class="text-center">
          <h5>Do you want to delete?</h5>
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
              >
                Yes
              </vs-button>

              <vs-button color="danger" type="filled" @click="privStatus()">
                No
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>

    <div v-if="showExpModal == true">
      <view-expense-details
        :editExpParamsData="editExpParamsData"
        :budgetVersn="budgetVer"
      />
    </div>

    <vs-popup title="Update Budget Record" :active.sync="showEditPopup">
      <EditBudgetForm
        :cityList="cityList"
        :formData="editFormData"
        @close-popup="
          () => {
            this.showEditPopup = false;
          }
        "
      />
    </vs-popup>
  </div>
</template>

<script>
import EditBudgetForm from "./EditBudgetForm.vue";
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import BudgetService from "@/services/budgetService.js";
import CategoryService from "@/services/categoryService.js";
import CountryService from "@/services/countryService";
import SearchService from "@/services/searchService.js";
import viewBudgetDetails from "./viewBudgetDetails.vue";
import viewExpenseDetails from "./viewExpenseDetails.vue";
import budgetStatus from "./budgetStatus.vue";
import isAllowedBudget from "./isAllowedBudget.vue";
import EditBudget from "./editBudget.vue";
import VueSimpleSuggest from "vue-simple-suggest";
import VueSuggest from "vue-simple-suggest/lib";
import PermissionVariables from "@/services/UserManagementVaraibles.js";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    EditBudgetForm,
    AgGridVue,
    VueSuggest,
    viewExpenseDetails,
    VueSimpleSuggest,
    "switch-component": {
      template: `<span>KOZO</span>`,
    },
  },
  props: ["listType", "project_id"],
  data() {
    return {
      cityList: [],
      editFormData: {},
      showEditPopup: false,
      limits: [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: "all", label: "All" },
      ],
      limit: { value: 10, label: 10 },
      listData: [],
      pagination: {
        from: 0,
        to: 0,
        last_page: 1,
        total: 0,
      },
      editExpParamsData: null,
      showExpModal: false,
      searchQuery: "",
      refresh: "",
      OrganizationID: window.localStorage.getItem("OrganizationID"),
      permissionPageAccessArry: window.localStorage.getItem(
        "permissionPageAccess"
      )
        ? localStorage.getItem("permissionPageAccess").split(",")
        : "",
      PermissionVariables: PermissionVariables,
      loginUserRole: window.localStorage.getItem("UserRole"),
      excelurl: BASEURL,
      categories: [],
      subCategories: [],
      categoryTemp: [],
      SubcategoryTemp: [],
      totalPages: 0,
      submitStatus: true,
      searchPayload: {},
      archive: this.listType,
      departments: [],
      gridOptions: {},
      searchBudgetList: [],
      budgetCodes: [],
      maxPageNumbers: 7,
      popupActive: false,
      popupActiveDeleteBudget: false,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
      },
      form: {
        selectedCategory: "",
        selectedSubCategory: "",
        budgetCode_value: "",
        budgetAmount: "",
        budget_active: "1",
      },
      budgetCodesList: ["ATL", "BTL", "MUSIC", "OTHER", "POST", "VFX"],
      columnDefs: [],
      organization_id: "",
      fixedOrVar: ["Fixed", "Variable"],
      fixedOrVarValue: "",
    };
  },
  beforeMount() {
    this.getCityList();
    this.organization_id = localStorage.getItem("OrganizationID");
    this.columnDefs = [
      { headerName: "Code", field: "budget_code", filter: false, width: 100 },
      {
        headerName: "Account",
        field: "category_code",
        filter: false,
        width: 150,
      },
      {
        headerName: "Category",
        field: "parent_category_name",
        filter: false,
        width: 250,
      },
      {
        headerName: "Subcategory",
        field: "category_name",
        filter: false,
        width: 250,
      },
      { headerName: "City", field: "city_name", filter: false, width: 150 },
      {
        headerName: "No. of Units 1",
        field: "unit1",
        filter: false,
        width: 150,
      },
      {
        headerName: "Units Description 1",
        field: "unit1_description",
        filter: false,
        width: 250,
      },
      {
        headerName: "No. of Units 2",
        field: "unit2",
        filter: false,
        width: 150,
      },
      {
        headerName: "Units Description 2",
        field: "unit2_description",
        filter: false,
        width: 250,
      },
      { headerName: "Rate", field: "rate", filter: false, width: 100 },
      {
        headerName: "Budget Amount",
        field: "formattedBudgetAmount",
        filter: false,
        width: 150,
      },
      {
        headerName: "Actual Expense",
        field: "actual_amount",
        filter: false,
        width: 150,
      },
      // {
      //   headerName: "Paid Amount",
      //   field: "paidAmount",
      //   filter: false,
      //   width: 150,
      // },
      {
        headerName: "Balance Amount",
        field: "balance_amount",
        filter: false,
        width: 150,
      },
      { headerName: "Comment", field: "comment", filter: false, width: 150 },
      {
        headerName: "Allow to add expense",
        field: "",
        filter: false,
        width: 200,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.BudgetEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: isAllowedBudget,
      },
      {
        headerName: "Status",
        field: "budget_active",
        filter: false,
        width: 120,
        sortable: false,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.BudgetEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: budgetStatus,
      },
      {
        headerName: "Edit",
        // field: "budget_id",
        field: "edit_budget",
        filter: false,
        width: 120,
        hide:
          (this.archive == "archive" ||
            !this.permissionPageAccessArry.includes(
              PermissionVariables.BudgetEdit
            )) &&
          this.loginUserRole != "Admin"
            ? true
            : false,
        sortable: false,
        cellStyle: { "text-align": "center" },
        cellRenderer: () =>
          '<span class="text-center table-edit-icon"><i class="text-center material-icons">edit</i></span>',
        // cellRendererFramework: EditBudget,
      },
      {
        headerName: "View",
        field: "budget_id",
        filter: false,
        width: 120,
        sortable: false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: viewBudgetDetails,
      },
    ];

    this.gridOptions = {
      getRowStyle(params) {
        let temp_str = params.data.actual_amount;
        if (temp_str.length > 3) {
          temp_str = params.data.actual_amount.replace(/\,/g, "");
        }

        let temp_str1 = params.data.budget_amount;
        if (temp_str1.length > 3) {
          temp_str1 = params.data.budget_amount.replace(/\,/g, "");
        }
        if (parseInt(temp_str1) < parseInt(temp_str)) {
          return { background: "red" };
        } else {
          return null;
        }
      },
    };
  },
  mounted() {
    if (this.project_id) {
      this.$nextTick(() => this.getBudgetListData());
    }
  },
  watch: {
    project_id: {
      handler: function(newVal, oldVal) {
        this.$nextTick(() => this.getBudgetListData());
      },
    },

    limit() {
      this.$nextTick(() => this.getBudgetListData());
    },

    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
      }
    },
    form: {
      handler: function(newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    is_data() {
      return Object.keys(this.budgetList).length > 0;
    },
    budgetList() {
      if (this.projectId) {
        if (this.refresh != null && this.refresh != "") {
          return this.searchBudgetList;
        } else {
          return this.$store.getters.getbudgetlist;
        }
      } else {
        return [];
      }
    },
    isDelete() {
      return this.$store.state.is_delete;
    },
    budgetPagination() {
      return this.$store.state.budgetObject.pagination.last_page;
    },
    projectId() {
      return this.$store.state.budgetObject.projectId;
    },
    budgetVer() {
      return this.$store.state.budgetversion;
    },
    budgetVerLength() {
      return this.$store.state.budgetVersionLength;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.$nextTick(() => this.getBudgetListData(val));
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    getCityList() {
      CountryService.getAllCity()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.cityList = data.data;
          } else this.cityList = [];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBudgetListData(page) {
      if (this.project_id) {
        this.$vs.loading();
        BudgetService.getAllBudget({
          project_id: this.projectId,
          page: page || this.currentPage,
          limit: this.limit.value,
        })
          .then((response) => {
            this.$vs.loading.close();
            if (response.data.Status) {
              this.listData = response.data.data;
              this.pagination = response.data.pagination;
            } else {
              this.listData = [];
              this.pagination = {
                from: 0,
                to: 0,
                last_page: 1,
                total: 0,
              };
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log("Error => ", error.message);
          });
      }
    },

    onCellClicked(params) {
      if (
        params.colDef.field == "budget_code_num" ||
        params.colDef.field == "category_name" ||
        params.colDef.field == "sub_category_name" ||
        params.colDef.field == "formattedBudgetAmount" ||
        params.colDef.field == "actual_amount" ||
        params.colDef.field == "balance_amount" ||
        params.colDef.field == "fixvar" ||
        params.colDef.field == "nos" ||
        params.colDef.field == "period" ||
        params.colDef.field == "rate" ||
        params.colDef.field == "balance_amount"
      ) {
        this.showExpModal = false;
        this.editExpParamsData = params;
        setTimeout(() => {
          this.showExpModal = true;
        }, 300);
      }

      if (params.colDef.field === "edit_budget") {
        this.editFormData = params.data;
        this.showEditPopup = true;
      }
    },
    onRowSelected(event) {
      window.alert(
        "row " + event.node.data.athlete + " selected = " + event.node.selected
      );
    },
    selectProject: function() {
      this.$vs.notify({
        time: 5000,
        title: "Select Project",
        iconPack: "feather",
        icon: "check_box",
        color: "warning",
      });
    },
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    updateSearchQuery(val) {
      this.searchPayload = {
        project_id: this.projectId,
      };
      this.refresh = this.searchQuery;
      // this.gridApi.setQuickFilter(val);
      SearchService.getAllSerchResult(val, "budget/project", this.searchPayload)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.listData = data.data;
          } else {
            this.listData = [];
          }
        })
        .catch((error) => {
          this.$vs.notify({
            time: 5000,
            title: "Data Not Found",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    showAddBudgetModal() {
      this.getCategoryList();
      this.getBudgetCodeList();
      let isProjectSelected = this.projectId;
      if (isProjectSelected) {
        this.popupActive = true;
      } else {
        this.$vs.notify({
          time: 5000,
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
    deleteBudgetModal: function() {
      let isProjectSelected = this.projectId;
      if (isProjectSelected) {
        this.popupActiveDeleteBudget = true;
      } else {
        this.$vs.notify({
          time: 5000,
          title: "Select Project",
          iconPack: "feather",
          icon: "check_box",
          color: "warning",
        });
      }
    },
    onChangeCheckbox: function(e, value) {
      this.$vs.loading();
      let id = this.projectId;
      let budgetVer = this.budgetVer;
      BudgetService.deleteBudget(id, budgetVer)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            this.$vs.notify({
              time: 5000,
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            setTimeout(() => {
              eventBus.$emit("refreshTableBudgetList", data);
            }, 1);
            this.popupActiveDeleteBudget = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 5000,
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.popupActiveDeleteBudget = false;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 5000,
            title: "Error!",
            text: error.message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    privStatus: function() {
      this.popupActiveDeleteBudget = false;
    },
    getBudgetCodeList: function() {
      this.$vs.loading();
      let id = this.projectId;
      BudgetService.getBudgetCodes(id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status) {
            if (data.data.length > 0) {
              this.budgetCodes = data.data;
            } else {
              this.budgetCodes = this.budgetCodesList;
            }
          } else {
            this.$vs.notify({
              time: 5000,
              title: "Data Not Found",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 5000,
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    getCategoryList: function() {
      this.$vs.loading();
      let id = this.projectId;
      CategoryService.getAllcategoriesByProject(id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.categories = data.data.filter((x) => {
              if (String(x.parent_id) === "0") {
                return x;
              }
            });
            this.categoryTemp = this.categories;
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 5000,
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    onSuggestSelect: function(selected) {
      this.form.selectedSubCategory = null;
      this.getSubcategoryList(selected.category_id || null);
    },
    getSubcategoryList: function(parent_id) {
      let ID = this.projectId;
      CategoryService.getSubCategoriesByParentCategory(parent_id, ID)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.subCategories = data.data;
            this.SubcategoryTemp = this.subCategories.filter((x) => {
              return x.category_name;
            });
          }
        })
        .catch((error) => {});
    },
    submitForm: function() {
      this.$validator.validateAll().then((result) => {
        this.submitStatus = false;
        if (result) {
          this.$vs.loading();
          let payload = {
            budget_code: this.form.budgetCode_value,
            category_name: this.form.selectedCategory.category_name,
            sub_category_name: this.form.selectedSubCategory.category_name,
            budget_amount: this.form.budgetAmount,
            budget_active: this.form.budget_active,
            project_id: this.projectId,
            budgetVer: this.budgetVer,
            city_id: this.form.city.city_id || null,
            unit1: this.form.unit1 || null,
            unit1_description: this.form.unit1_description,
            unit2: this.form.unit2 || null,
            unit2_description: this.form.unit2_description,
            rate: this.form.rate || null,
            comment: "",
          };
          if (this.organization_id == 5) {
            payload.fixvar = this.fixedOrVarValue;
          }
          BudgetService.addBudget(payload)
            .then((response) => {
              this.$vs.loading.close();
              const { data } = response;
              if (data.Status) {
                this.$vs.notify({
                  time: 5000,
                  title: "Added Successfully",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "success",
                });
                this.popupActive = false;
                setTimeout(() => {
                  eventBus.$emit("refreshBudgetTable", data);
                }, 1);
                this.clearForm();
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  time: 5000,
                  title: "Error!",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.$vs.notify({
                time: 5000,
                title: "Error!",
                text: error.message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
        }
      });
    },
    clearForm: function() {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.$validator.reset();
    },
  },
};
</script>

<style>
pre.selected {
  margin: 8px 0;
  height: 295px;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event {
  background-color: white;
  border: 1px solid #cde;
  border-radius: 4px;
}
.v-model-event.selected {
  color: red;
}
.v-model-event:hover {
  border: 1px solid #2874d5;
  background-color: #2874d5;
  color: white;
}
.vue-simple-suggest .suggest-item .text {
  display: inline-block;
  line-height: 1;
  vertical-align: text-bottom;
  overflow: hidden;
  max-width: 72%;
  text-overflow: ellipsis;
}
.vue-simple-suggest .suggest-item .text span {
  white-space: nowrap;
}
.vue-simple-suggest .suggest-item button {
  float: right;
  line-height: 1;
  margin-left: 4px;
}
.vue-simple-suggest-enter-active.suggestions,
.vue-simple-suggest-leave-active.suggestions {
  transition: opacity 0.2s;
}
.vue-simple-suggest-enter.suggestions,
.vue-simple-suggest-leave-to.suggestions {
  opacity: 0 !important;
}
</style>
